import React from 'react';
import { graphql } from 'gatsby';
import { Section } from 'src/components/Section';
import Layout from 'src/components/Layout';
import sizes from 'src/style/sizes';
import { mediaQueries } from 'src/style/breakpoints';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { FormHeader } from 'src/components/Form';
import { FormSteps } from 'src/components/EventForm';
import { useFutureDataFromMeetup } from 'src/utils/useFutureDataFromMeetup';
import MoonLoader from 'react-spinners/MoonLoader';
import colors from 'src/style/colors';

const EventSubscribe = ({
  location,
  pageContext: { dataFromMeetup },
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const selectedEvent = location.state ? location.state : undefined;
  const [isLoading, futureDataFromMeetup] = useFutureDataFromMeetup(dataFromMeetup);

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName="Inscription événement"
      pageType="form"
      formStart={true}
      formType="event"
      metaTitle="Inscrivez-vous à cet évènement | Le Reacteur"
      metaDescription="Vous souhaitez assister à cet évènement ? Inscrivez-vous !"
    >
      <Section
        theme="primary"
        css={{
          [mediaQueries.phoneOnly]: {
            height: sizes.purpleBgHeightMobile,
          },
          [mediaQueries.tabletPortraitUp]: {
            height: sizes.purpleBgHeightTablet,
          },
          [mediaQueries.desktopUp]: {
            height: sizes.purpleBgHeightDesktop,
          },
        }}
      />
      <TopbarStaticSpace />
      <Section theme="secondary">
        <div
          css={{
            [mediaQueries.phoneOnly]: {
              marginTop: -160,
            },
            [mediaQueries.tabletPortraitUp]: {
              marginTop: -370,
            },
            [mediaQueries.tabletLandscapeUp]: {
              marginTop: -330,
            },
            [mediaQueries.desktopUp]: {
              marginTop: sizes.marginTopContainer,
            },
          }}
        >
          {isLoading ? (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <MoonLoader color={colors.white} size={20} />
            </div>
          ) : (
            <>
              <FormHeader mainTitle="Inscrivez-vous" />
              <FormSteps dataFromMeetup={futureDataFromMeetup} selectedEvent={selectedEvent} />
            </>
          )}
        </div>
      </Section>
    </Layout>
  );
};
export default EventSubscribe;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        coverArticle
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            title
            author
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 970) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tags
            preview
            published
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
